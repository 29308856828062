import {
  Any,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class UspsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static cityStateZipGet(
    params: {
      /**  */
      city?: string;
      /**  */
      state?: string;
      /**  */
      zipCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Usps/CityStateZip';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { city: params['city'], state: params['state'], zipCode: params['zipCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cityStateZipList(options: IRequestOptions = {}): Promise<Any[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Usps/CityStateZip/List';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cityStateZipSeek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Usps/CityStateZip/Seek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addressSuggested(
    params: {
      /**  */
      streetAddress?: string;
      /**  */
      secondaryAddress?: string;
      /**  */
      city?: string;
      /**  */
      state?: string;
      /**  */
      urbanization?: string;
      /**  */
      zipCode?: string;
      /**  */
      zipPlus4?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Usps/Address/Suggested';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        streetAddress: params['streetAddress'],
        secondaryAddress: params['secondaryAddress'],
        city: params['city'],
        state: params['state'],
        urbanization: params['urbanization'],
        zipCode: params['zipCode'],
        zipPlus4: params['zipPlus4']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
