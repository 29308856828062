<template>
    <div>
        <label class="main-label" v-if="label">{{required ? label + '*' : label}}</label>

        <v-row v-if="address.Locked">
            <v-col md="6" cols="12">
                <div class="locked-address-container d-flex align-center">
                    <div style="display: inline-block;" class="pl-1">
                        <v-icon v-if="addressType == 'PHYSICAL'" size="24" class="preview-icon">fas fa-map-marker-alt</v-icon>
                        <v-icon v-if="addressType == 'MAILING'" size="24" class="preview-icon">fas fa-envelope</v-icon>
                    </div>
                    <div style="display: inline-block; vertical-align: middle;" class="pl-3">
                        <h4>
                            {{formatStreetAddress()}}<br>
                            {{address.City ? address.City + ',' : ''}} {{address.State}} {{address.PostalCode}} {{address.CountryCode}}
                        </h4>
                    </div>
                    <v-spacer />
                    <v-btn icon @click="unlock()" class="ml-2" v-show="!disabled"
                        title="Edit Address" key="edit">
                        <i class="fas fa-pen" style="font-size: 18px;"></i>
                    </v-btn>
                </div>
                <p v-show="!disabled" class="mb-0 mt-2" style="font-size: 14px; line-height: 16px;">Accepted Address - click on the pencil icon to the right to re-enter the address.</p>
            </v-col>
            <v-col md="6" cols="12">
                <template v-if="suggestedAddress && address.Locked">
                    <div style="display: inline-block; vertical-align: top;">
                        <p style="display: block; font-size: 14px;" class="suggestedAddress mb-1">
                            <strong>Would you like to use this suggested mailing address?</strong>
                        </p>
                        <p style="display: inline-block; vertical-align: top;" class="suggestedAddress mb-2 mr-4">
                            {{suggestedAddress.streetAddress}}<br>
                            {{suggestedAddress.city}}, {{suggestedAddress.state}} {{suggestedAddress.zipCode}} {{suggestedAddress.countryCode}}
                        </p>
                        <div style="display: inline-block; white-space: nowrap;">
                            <v-btn color="secondary" type="button" @click="useSuggestedAddress" key="suggested1" style="vertical-align: bottom;">Yes</v-btn>
                            <v-btn color="secondary" text type="button" @click="ignoreSuggestedAddress" key="suggested2" style="vertical-align: bottom;" class="ml-2">No</v-btn>
                        </div>
                        
                    </div>
                </template>
            </v-col>
        </v-row>
        
        
        <div v-else-if="!address.Locked">
            <v-card class="pb-1 px-0">
                <v-card-text>
                    <v-row v-if="!address.Locked && !presetMode">
                        <v-col cols="12" class="py-0">
                            <DynamicFormControl 
                                :control="{
                                    type: 'RadioButtonGroup',
                                    value: mode,
                                    name: 'Mode',
                                    config: {
                                        options: [
                                            {value: 'GOOGLE', text: 'Google Lookup'},
                                            {value: 'ADVANCED', text: 'Advanced Entry'}
                                        ],
                                        small: true,
                                        short: true,
                                        disabled: serviceLoading || disabled,
                                        mandatory: true
                                    }
                                }" 
                                @change="mode = $event.value" 
                            />
                            <br>
                        </v-col>
                    </v-row>
                    <ValidationObserver tag="span" ref="addressObserver" vid="Address">
                        <fieldset>
                            <v-row>
                                <v-col :md="addressType == 'MAILING' ? 12 : 6" cols="12" v-show="mode == 'GOOGLE'" class="py-0">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'GooglePlaces',
                                            name: 'GoogleStreet',
                                            config: {
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                ...googlePlacesProps,
                                            }
                                        }" 
                                        @change="setGoogleAddress($event.value)"
                                        @keypress="clearGoogleAddressErrors" 
                                    />
                                    <p style="color: #333333; font-size: 14px; line-height: 16px;">If address cannot be found using <strong>Google Lookup</strong>, please use <strong>Advanced Entry</strong>.</p>
                                </v-col>
                                <v-col md="12" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="addressType == 'MAILING'" key="StreetAddress">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.StreetAddress,
                                            name: 'StreetAddress',
                                            config: {
                                                label: 'Street Address',
                                                rules: advancedRules,
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 50
                                            }
                                        }" 
                                        @change="address.StreetAddress = $event.value" 
                                    />
                                </v-col> 
                                <v-col md="2" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="addressType == 'PHYSICAL'" key="StreetNumber">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.StreetNumber,
                                            name: 'StreetNumber',
                                            config: {
                                                label: 'Street #',
                                                rules: advancedRules,
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 10
                                            }
                                        }" 
                                        @change="address.StreetNumber = $event.value" 
                                    />
                                </v-col>
                                <v-col md="4" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="addressType == 'PHYSICAL'" key="StreetName">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.StreetName,
                                            name: 'StreetName',
                                            config: {
                                                label: 'Street Name',
                                                rules: advancedRules,
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 39
                                            }
                                        }" 
                                        @change="address.StreetName = $event.value" 
                                    />
                                </v-col>
                                <v-col md="3" class="py-0" v-if="addressType == 'PHYSICAL'" key="UnitType">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'Dropdown',
                                            value: address.UnitType,
                                            name: 'UnitType',
                                            config: {
                                                label: 'Unit Type',
                                                rules: unitTypeRules,
                                                disabled: serviceLoading || address.Locked || disabled,
                                                clearable: true,
                                                options: unitTypes,
                                                short: true
                                            }
                                        }" 
                                        @change="address.UnitType = $event.value" 
                                    />
                                </v-col>
                                <v-col md="3" v-show="address.UnitType != '#'" class="py-0" v-if="addressType == 'PHYSICAL'" key="UnitNumber">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.UnitNumber,
                                            name: 'UnitNumber',
                                            config: {
                                                label: 'Unit #',
                                                rules: unitNumberRules,
                                                disabled: serviceLoading || address.Locked || disabled,
                                                clearable: true,
                                                short: true,
                                                maxlength: 10
                                            }
                                        }" 
                                        @change="address.UnitNumber = $event.value" 
                                    />
                                </v-col>
                                <!-- <v-col md="1" v-show="address.Locked" class="pl-0">
                                    <v-btn icon large @click="unlock" color="secondary" style="margin-top: 2px;" title="Edit Address">
                                        <i class="fas fa-pen" style="font-size: 24px;"></i>
                                    </v-btn>
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col :md="showCountryCode ? 9 : 12" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="address.CountryCode == 'US'" key="CityStateZip">
                                  <DynamicFormControl 
                                        :control="{
                                            type: 'CityStateZipAutocompleteV1',
                                            name: 'CityStateZip',
                                            value: createCityStateZipObject,
                                            config: {
                                                disabled: serviceLoading || address.Locked || presetCityStateZip != null || disabled,
                                                short: true,
                                            }
                                        }" 
                                        @change="updateCityStateZip" 
                                    />
                                </v-col>
                                <v-col :md="showCountryCode ? 3 : 4" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="address.CountryCode != 'US'" key="City">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.City,
                                            name: 'City',
                                            config: {
                                                label: 'City',
                                                rules: 'required',
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 30
                                            }
                                        }" 
                                        @change="address.City = $event.value" 
                                    />
                                </v-col>
                                <v-col :md="showCountryCode ? 3 : 4" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="address.CountryCode != 'US'" key="State">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.State,
                                            name: 'State',
                                            config: {
                                                label: 'Region',
                                                rules: '',
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 29
                                            }
                                        }" 
                                        @change="address.State = $event.value" 
                                    />
                                </v-col>
                                <v-col :md="showCountryCode ? 3 : 4" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="address.CountryCode != 'US'" key="PostalCode">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'TextInput',
                                            value: address.PostalCode,
                                            name: 'PostalCode',
                                            config: {
                                                label: 'Postal Code',
                                                rules: '',
                                                disabled: serviceLoading || address.Locked || disabled,
                                                short: true,
                                                maxlength: 10
                                            }
                                        }" 
                                        @change="address.PostalCode = $event.value" 
                                    />
                                </v-col>
                                <v-col md="3" cols="12" v-show="mode == 'ADVANCED'" class="py-0" v-if="showCountryCode" key="CountryCode">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'Dropdown',
                                            value: address.CountryCode,
                                            name: 'CountryCode',
                                            config: {
                                                label: 'Country',
                                                rules: 'required',
                                                disabled: serviceLoading || address.Locked || addressType == 'PHYSICAL' || disabled,
                                                clearable: false,
                                                short: true,
                                                options: countries
                                            }
                                        }" 
                                        @change="address.CountryCode = $event.value" 
                                    />
                                </v-col>
                            </v-row>
                        </fieldset>
                    </ValidationObserver>
                    

                    <br v-if="showLockButton || savedAddress">

                    <div class="text-right">

                        <template v-if="savedAddress && !serviceLoading">
                            <FormButton v-bind="{label: 'Cancel', color: 'secondary', type: 'button', text: true, disabled: disabled, size: 'x-large'}" @click="cancel()"></FormButton>
                        </template>

                        <template v-if="showLockButton">
                            <v-btn color="secondary" x-large type="button" @click="onSubmit" :disabled="serviceLoading || disabled" key="save" class="ml-2">
                                <Loading v-if="serviceLoading" :size="24" class="mr-2" />
                                Accept Address
                            </v-btn>
                            &nbsp;
                        </template>
                        
                    </div>

                    <div v-if="!address.Locked" class="hasError">
                        <ValidationProvider :rules="'addressOpen:' + mode" v-slot="{ errors }">
                            <input v-model="address.Locked" hidden />
                            <br v-if="errors.length">
                            <ErrorMessages :errors="errors"></ErrorMessages>
                        </ValidationProvider>
                    </div>
                </v-card-text>
            </v-card>
        </div>

      <div v-if="displayLimitedStateAlert">
          <v-alert dense outlined type="info" icon="fa-solid fa-circle-info" class="mt-2">
              We are unable to offer a quote for this state at this time; however, you can proceed with the quoting process if you would like to refer this risk to TFIA, an Amwins Company.
          </v-alert>
      </div>
        

    </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from './FormButton.vue'
import { UnitTypes, StateNames } from '@/scripts/options';
import { sanitizeAddress } from '@/scripts/helper';
import { UspsService } from '@/api/UspsService'
import { FeatureFlagsService } from '@/api/FeatureFlagsService'
import Vue from 'vue'

export default {
    name: 'AddressV1',
    components: {
        DynamicFormControl,
        FormButton
    },
    data() {
        return {
            savedAddress: null,
            mode: 'GOOGLE',
            address: {
                StreetAddress: null,
                StreetNumber: null,
                StreetName: null,
                UnitType: null,
                UnitNumber: null,
                City: null,
                State: null,
                PostalCode: null,
                County: null,
                CountryCode: null,
                Locked: false,
                Formatted: null,
            },
            riskTypeKey: null,
            serviceLoading: false,
            suggestedAddress: null,
            limitedStatesList:  ['AL', 'CA', 'CT', 'FL', 'GA', 'IN', 'LA', 'MA', 'MO', 'MS', 'NC', 'NJ', 'OH', 'PA', 'RI', 'SC', 'TN', 'TX', 'VA', 'WA', 'WV']
        }
    },
    props: {
        value: Object,
        label: String,
        rules: String,
        required: Boolean,
        addressType: String,
        module: String,
        disabled: Boolean,
        errors: {
            type: Array,
            default: () => []
        },
        presetMode: String,
        presetCityStateZip: Object,
        presetCountry: String,
        enableLimitedStatesAlert:  {
            type: Boolean,
            default: false
        },
        unitTypeRequired:  {
            type: Boolean,
            default: true
        }
    },
    emits: [
        'change'
    ],
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal) {
                    //this.savedAddress = newVal;
                    this.address = newVal;
                    if (this.address.Locked === undefined) {
                        this.address.Locked = false;
                    }
                }
            }
        },
        country(newVal, oldVal) {
            if (oldVal && oldVal != 'US' && newVal == 'US') {
                this.address.City = null;
                this.address.State = null;
                this.address.PostalCode = null;
            }
        }
    },
    computed: {
        countries() {
            return this.$store.getters['resource/getCountriesV1'].map(o => { return {
                text: o.name,
                value: o.alpha2
            }});
        },
        createCityStateZipObject() {
            if (!this.address.City || !this.address.State || !this.address.PostalCode) return null;

            let stateName = "";
            const s = StateNames.find(s => s.abbreviation == this.address.State);
            if (s) {
                stateName = s.abbreviation;
            }

            return {
              formatted: this.address.City + ", " + stateName + " " + this.address.PostalCode,
              city: this.address.City,
              state: stateName,
              zipCode: this.address.PostalCode
            }
        },
        googlePlacesProps() {
            return {
                label: "Address (Type to Search)",
                required: this.$props.required,
                addressType: this.$props.addressType
            }
        },
        unitTypes() {
            let options = UnitTypes.map(u => { return {
                value: u.Key,
                text: u.Name
            } });

            if (this.riskTypeKey == 'CONDO') {
                options = options.filter(o => o.value != 'BLDG');
            } else {
                options = options.filter(o => o.value != '#' && o.value != 'PH')
            }

            return options;
        },
        country() {
            if (!this.address) return null;
            return this.address.CountryCode;
        },
        showCountryCode() {
            return !this.presetCityStateZip && this.addressType != 'PHYSICAL' && !this.presetCountry;
        },
        advancedRules() {
            return this.mode == 'ADVANCED' ? 'required' : null;
        },
        advancedRequired() {
            return this.mode == 'ADVANCED';
        },
        unitTypeRules() {
            return (this.unitTypeRequired && (this.address.UnitNumber || this.riskTypeKey == 'CONDO')) ? 'required' : null;
        },
        unitNumberRules() {
            if (!this.address.UnitType || !UnitTypes.find(u => u.Key == this.address.UnitType)) return null;
            return UnitTypes.find(u => u.Key == this.address.UnitType).IsRange ? 'required' : null;
        },
        unitNumberRequired() {
            return this.unitNumberRules?.indexOf('required') > -1;
        },
        showLockButton() {
            return this.addressIsComplete && !this.address.Locked
        },
        addressIsComplete() {
            if (this.address.CountryCode == 'US') {
                return (this.address.StreetAddress || (this.address.StreetNumber && this.address.StreetName)) && this.address.City && this.address.State && this.address.PostalCode && this.address.CountryCode;
            } else {
                return (this.address.StreetAddress || (this.address.StreetNumber && this.address.StreetName)) && this.address.CountryCode;
            }
        },
        displayLimitedStateAlert() {
            if (!this.enableLimitedStatesAlert || !this.address.State || this.addressType != 'PHYSICAL') {
                return false
            }

            return !this.limitedStatesList.includes(this.address.State)
        }
    },
    methods: {
        sanitizeAddress,
        showErrorIncompleteGoogleAddress() {
            setTimeout(() => {
                this.$refs.addressObserver.refs.GoogleStreet.setErrors(["Incomplete Address. Please use 'Advanced&nbsp;Entry.'"])
            }, 100)
        },
        clearGoogleAddressErrors() {
            setTimeout(() => {
                if (this.$refs.addressObserver.refs.GoogleStreet.errors.length > 0) {
                    this.$refs.addressObserver.refs.GoogleStreet.setErrors([]);
                }
            }, 100)
        },
        setGoogleAddress(event) {
            if (event) {
                if (this.addressType == 'PHYSICAL') {
                    
                    if (!event.StreetNumber || event.StreetNumber === '' || !event.StreetName || event.StreetName === '') {
                        this.showErrorIncompleteGoogleAddress(); 
                        return;
                    } else {
                        this.address.StreetNumber = event.StreetNumber;
                        this.address.StreetName = event.StreetName;
                    }

                } else {
                    this.address.StreetAddress = event.StreetNumber + ' ' + event.StreetName;
                }

                this.address.City = event.City;
                this.address.State = event.State;
                this.address.PostalCode = event.PostalCode;
                this.address.CountryCode = event.CountryCode;

                this.sanitizeAddressFields()
                this.mode = 'ADVANCED';

                UspsService.cityStateZipGet({ city: this.address.City, state: this.address.State, zipCode: this.address.PostalCode }, {
                    handleError: false
                })
                .then((cityStateZip) => {
                    if (!cityStateZip) {
                        this.$refs.addressObserver.setErrors({ CityStateZip: ['City / State / Zip Code not found within known USPS locations'] })
                    }
                }).catch((e) => {
                    this.$refs.addressObserver.setErrors({ CityStateZip: ['City / State / Zip Code not found within known USPS locations'] })
                })
            }
            
        },
        onSubmit() {
            if (this.$refs.addressObserver.refs.CityStateZip.errors.length == 0) {
                this.$refs.addressObserver.validate()
                .then(valid => {
                    if (valid) {
                        this.save();
                    }
                });
            }            
        },
        sanitizeAddressFields() {
            this.address.StreetNumber = this.sanitizeAddress(this.address.StreetNumber);
            this.address.StreetName = this.sanitizeAddress(this.address.StreetName);
            this.address.UnitType = this.sanitizeAddress(this.address.UnitType);
            this.address.UnitNumber = this.sanitizeAddress(this.address.UnitNumber);
            this.address.StreetAddress = this.sanitizeAddress(this.address.StreetAddress);
            this.address.City = this.sanitizeAddress(this.address.City)
            this.address.State = this.sanitizeAddress(this.address.State);
            this.address.PostalCode = this.sanitizeAddress(this.address.PostalCode);
            this.address.CountryCode = this.sanitizeAddress(this.address.CountryCode);
        },
        save() {
            this.sanitizeAddressFields();
            
            if (this.addressType == 'MAILING' && this.address.CountryCode == 'US') {
                this.serviceLoading = true;
                this.suggestedAddress = null;

                let secondaryAddress = null;
                if (this.address.UnitNumber) {
                    secondaryAddress = this.address.UnitType + ' ' + this.address.UnitNumber
                }
                UspsService.addressSuggested({
                    streetAddress: this.address.StreetAddress,
                    secondaryAddress: secondaryAddress,
                    city: this.address.City,
                    state: this.address.State,
                    zipCode: this.address.PostalCode
                }, {
                  handleError: false
                }).then((suggestedAddress) => {
                    this.suggestedAddress = suggestedAddress.address;
                    if (suggestedAddress) {
                        this.suggestedAddress.countryCode = 'US'
                    }

                    if (!suggestedAddress) {
                        this.suggestedAddress = null;
                    } else if (
                        suggestedAddress.address.streetAddress == this.address.StreetAddress &&
                        suggestedAddress.address.city == this.address.City &&
                        suggestedAddress.address.state == this.address.State &&
                        suggestedAddress.address.zipCode == this.address.PostalCode
                    ) {
                        this.suggestedAddress = null;
                    }
                    this.lock();
                    this.serviceLoading = false;
                }).catch(() =>{})
                .finally(() => {this.serviceLoading = false;})
            }

            this.lock();

        },
        lock() {
            this.mode = 'ADVANCED';
            this.address.Locked = true;
            this.address.Formatted = this.formatAddress();
            this.$emit('change', this.address);   
            this.$emit('validate');
        },
        unlock() {
            this.suggestedAddress = null;
            this.address.Locked = false;
            this.savedAddress = {...this.address};

            if (this.addressType == 'PHYSICAL' && (this.address.StreetName || this.address.StreetAddress)) {
                this.mode = 'ADVANCED';
            }

            this.$emit('change', this.address);   
        },
        cancel() {
            this.address = {...this.savedAddress};
            this.lock();
        },
        useSuggestedAddress() {
            this.address.StreetAddress = this.suggestedAddress.streetAddress;
            this.address.City = this.suggestedAddress.city;
            this.address.State = this.suggestedAddress.state;
            this.address.PostalCode = this.suggestedAddress.zipCode;
            this.address.CountryCode = 'US'
            this.lock();
            this.suggestedAddress = null;
        },
        ignoreSuggestedAddress() {
            this.lock();
            this.suggestedAddress = null;
        },
        formatAddress() {
            let a = '';
            a += (this.address.StreetAddress || (this.address.StreetNumber + ' ' + this.address.StreetName));
            if (this.address.UnitType && this.address.UnitType != '#') a += ' ' + this.address.UnitType + ( this.address.UnitNumber ? ' ' + this.address.UnitNumber : '');
            a += ', ';
            a += (this.address.City ? this.address.City + ', ': '') + this.address.State + ' ' + this.address.PostalCode + ' ' + this.address.CountryCode;
            return a;
        },
        formatStreetAddress() {
            if (!this.address) return null;

            if (this.addressType == 'MAILING') {
                return this.address.StreetAddress;
            }
            else {
                let s = this.address.StreetNumber + ' ' + this.address.StreetName + ' ';
                if (this.address.UnitType && this.address.UnitType != '#') s += this.address.UnitType + ' ';
                if (this.address.UnitNumber && this.address.UnitType != '#') s += this.address.UnitNumber;
                return s;
            }
        },
        updateCityStateZip(event) {
            if (event.value) {
                this.address.City = event.value.city;
                this.address.State = event.value.state;
                this.address.PostalCode = event.value.zipCode;
                this.address.CountryCode = "US";
            } else {
                this.address.City = null;
                this.address.State = null;
                this.address.PostalCode = null;
            }
        }
    },
    mounted() {
        if (!this.address.CountryCode && this.presetCountry) {
            Vue.set(this.address, 'CountryCode', this.presetCountry)
        }
        
        const coverageOption = this.$store.getters.getControlValue(this.module, 'CoverageOption');
        this.riskTypeKey = coverageOption == 'ho6' ? 'CONDO' : 'HOME';

        if (this.address.StreetName || this.address.StreetAddress) {
            this.mode = 'ADVANCED';
        }

        if (this.presetMode) {
            this.mode = this.presetMode;
        }

        if (this.presetCityStateZip) {
            this.address.City = this.presetCityStateZip.AddressCityName;
            this.address.State = this.presetCityStateZip.AddressStateKey;
            this.address.PostalCode = this.presetCityStateZip.AddressPostalCodeName;
            this.address.CountryCode = 'US';
        }

        if (!this.address.CountryCode) {
            this.address.CountryCode = 'US';
        }

        FeatureFlagsService.isEnabled({ featureName: "V1Wave4States" })
            .then((response) => {
                if (response) {
                    this.limitedStatesList = ['CO','DE','IA','KS','MD','MI','MT','NE','NH','OK','VT','WI','AL', 'CA', 'CT', 'FL', 'GA', 'IN', 'LA', 'MA', 'MO', 'MS', 'NC', 'NJ', 'OH', 'PA', 'RI', 'SC', 'TN', 'TX', 'VA', 'WA', 'WV']
                }
            });
    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

  .suggestedAddress,
  .locked-address-container * {
    color: #333333;
  }

  .preview-icon {
      display: inline-block;
      vertical-align: middle;
      color: $amwins-blue;
      width: 24px;
  }

  .locked-address-container {
      padding: 12px 8px;
      border: 2px solid #002B49;
      border-radius: 4px;
      display: block;
  }

  .main-label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
</style>
